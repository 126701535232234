import RegisterTokenModal from '@/components/modal/RegisterTokenModal.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'

const getRegisterTokenModalConfig = () => {
  return {
    attrs: {
      modalId: 'register_token',
      title: 'Désolé',
      clickToClose: false,
    },

    slots: {
      default: useModalSlot({
        component: RegisterTokenModal,
      }),
    },
  }
}

export const registerTokenModal = useCustomModal(getRegisterTokenModalConfig())

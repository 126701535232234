<template>
  <div>
    <div v-if="!requestDone">
      <p class="u-mb-4">
        Votre lien d’activation de compte n’est plus valide. Faites-vous
        renvoyer un nouveau lien d’activation à l’adresse email indiquée lors de
        votre inscription en cliquant sur le bouton ci-dessous.
      </p>
      <div
        v-if="errorMessage"
        class="is-invalid u-mb-3"
      >
        {{ errorMessage }}
      </div>
      <div class="u-d-f u-jc-fe">
        <ButtonSimple
          type="button"
          label="Renvoyer un lien"
          @click="resendToken"
        />
      </div>
    </div>
    <div v-else>
      <p class="u-mb-4">
        Un nouvel email de confirmation d'inscription vient de vous être envoyé.
        Cliquez sur le lien pour valider votre inscription.
        <br />
        <br />
        <strong>Attention, ce lien n'est valide que 48h.</strong>
      </p>
      <div class="u-d-f u-jc-fe">
        <ButtonSimple
          type="button"
          label="Fermer"
          @click="vfm.closeAll()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'

const vfm = useVfm()

const route = useRoute()
const register_token = route.query.register_verify as string

const errorMessage = ref('')
const requestDone = ref(false)

const resendToken = async () => {
  // resend new registration verification link
  const { error } = await useCustomFetch(API.POST__REGISTER_TOKEN, {
    method: 'POST',
    body: {
      register_token,
    },
  })

  if (error.value) {
    errorMessage.value =
      error.value.data.message ||
      `Une erreur s'est produite, veuillez réessayer ultérieurement.`
  } else {
    requestDone.value = true
  }
}

onMounted(async () => {
  await nextTick()
  const registerTokenCookie = useCookie('register_verify')
  registerTokenCookie.value = null
})
</script>

<style lang="scss" scoped>
.is-invalid {
  color: #ea5151;
}
</style>
